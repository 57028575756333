.carousel-item {
	height: 100%;
	min-height: 600px;
	img {
		object-fit: cover;
	}
}
.carousel-indicators {
	bottom: 0px;
	li {
		border: 2px solid $white;
		width: $carousel-indicator-width - 20;
		height: ($carousel-indicator-height * 10) - 20;
		@extend .rounded-circle;
		@extend .bg-transparent;
		@extend .mx-1;
	}
}
.carousel-control-prev, .carousel-control-next {
	text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
	background-color: transparent;
	font-size: 50px;
	width: 5%;
	height: 10%;
	top: 50%;
	background-image: unset;
	filter: unset;
	background-repeat: unset;
}
.carousel-caption {
	img {
		height: 200px;
	}
}
@include media-breakpoint-up(lg) {
	.carousel-caption {
		img {
			height: auto;
		}
	}
}