body {
    background: url('/dist/images/bg.jpg');
}

a {
    -webkit-transition: all .4s ease-in-out;
    -moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    @include hover-focus() {
        outline: none !important;
        -moz-outline-style: none !important;
        -webkit-outline-style: none !important;
    }
}

/*SPACERS*/

.space10 {
    margin-bottom: 10px;
}

.space15 {
    margin-bottom: 15px;
}

.space20 {
    margin-bottom: 20px;
}

.space25 {
    margin-bottom: 25px;
}

.space30 {
    margin-bottom: 30px;
}

.space35 {
    margin-bottom: 35px;
}

.space40 {
    margin-bottom: 40px;
}

.space50 {
    margin-bottom: 50px;
}

.space60 {
    margin-bottom: 60px;
}

.space70 {
    margin-bottom: 70px;
}

.space80 {
    margin-bottom: 80px;
}

.space90 {
    margin-bottom: 90px;
}

.space100 {
    margin-bottom: 100px;
}

.space110 {
    margin-bottom: 110px;
}

.space120 {
    margin-bottom: 120px;
}

.spacetop10 {
    margin-top: 10px;
}

.spacetop20 {
    margin-top: 20px;
}

.spacetop30 {
    margin-top: 30px;
}

.spacetop40 {
    margin-top: 40px;
}

.spacetop50 {
    margin-top: 50px;
}

.spacetop60 {
    margin-top: 60px;
}

.spacetop70 {
    margin-top: 70px;
}

.spacetop80 {
    margin-top: 80px;
}

.spacetop90 {
    margin-top: 90px;
}

.spacetop100 {
    margin-top: 100px;
}

/*BUTTONS*/

.btn-lg,
.btn-group-lg>.btn {
    float: none;
    padding: 30px 10px 30px;
    color: #000;
    text-decoration: none;
    text-shadow: 0 1px 0 #ffffff;
}

@media only screen and (max-width:768px) {
    .navbar-brand {
        width: 190px;
    }
    .btn-lg,
    .btn-group-lg>.btn {
        font-size: 12px;
        padding-top: 45px;
        padding-bottom: 40px;
    }
    .navbar-collapse {
        text-align: center;
    }
}

@media only screen and (max-width:800px) {
    .btn-lg,
    .btn-group-lg>.btn {
        font-size: 12px;
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

@media only screen and (max-width:980px) {
    .btn-lg,
    .btn-group-lg>.btn {
        font-size: 12px;
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

.btn-custom:focus,
.btn-custom.focus,
.btn-custom:active,
.btn-custom.active {
    color: #000;
    text-shadow: 0 0 5px #fff;
    text-decoration: none;
    background-color: transparent;
}

.btn-custom:hover,
.btn-custom:focus,
.btn-custom.focus,
.btn-custom:active,
.btn-custom.active {
    color: #555555;
    text-decoration: none;
    background-color: #e5e5e5;
    box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.125);
    background: rgba(186, 186, 186, 0.5);
    background: -moz-linear-gradient(top, rgba(186, 186, 186, 0.9) 0%, rgba(186, 186, 186, 0.9) 41%, rgba(255, 255, 255, 0) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(186, 186, 186, 0.9)), color-stop(41%, rgba(186, 186, 186, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
    background: -webkit-linear-gradient(top, rgba(186, 186, 186, 0.9) 0%, rgba(186, 186, 186, 0.9) 41%, rgba(255, 255, 255, 0) 100%);
    background: -o-linear-gradient(top, rgba(186, 186, 186, 0.9) 0%, rgba(186, 186, 186, 0.9) 41%, rgba(255, 255, 255, 0) 100%);
    background: -ms-linear-gradient(top, rgba(186, 186, 186, 0.9) 0%, rgba(186, 186, 186, 0.9) 41%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(to bottom, rgba(186, 186, 186, 0.9) 0%, rgba(186, 186, 186, 0.9) 41%, rgba(255, 255, 255, 0) 100%);
    opacity: 0.6;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#bababa', endColorstr='#ffffff', GradientType=0);
}

.btn-custom {
    color: #666;
    border-top: 0px;
    border-left: 0px;
    border-right: 1px solid #DBDBDB;
    outline: 0px;
    background: none;
}

.btn-custom-last-child {
    border-right: 0px solid #CCC;
}

.back-line {
    border: #a90329 5px solid;
    border-radius: 10px;
    margin-top: -33px;
}

/*HR*/

.hr-custom {
    border: rgba(255, 255, 255, .6) 5px solid;
    border-radius: 10px;
}

.btn-default:hover,
.btn-default:focus,
.btn-default.focus,
.btn-default:active,
.btn-default.active,
.open>.dropdown-toggle.btn-default {
    color: #fff;
    background-color: #06C;
    border-color: #adadad;
}

.dropdown-menu {
    background: #CCC;
}

.dropdown-menu>li>a {
    color: #666;
}

.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus {
    color: #000;
    text-decoration: none;
    background-color: transparent;
}

.dropdown-menu .divider {
    height: 4px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #666;
}

/*TABS*/

.nav-tabs {
    border-top: 2px solid #ddd;
    border-bottom: 2px solid #ddd;
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:hover,
.nav-tabs>li.active>a:focus {
    color: #F00;
    font-weight: 300;
    cursor: default;
    background-color: transparent;
    border: 0px solid #ddd;
    border-bottom-color: transparent;
}

.nav-tabs>li>a:hover {
    border-color: #eee #eee #ddd;
    color: #666;
}

.nav>li>a:hover,
.nav>li>a:focus {
    text-decoration: none;
    background-color: #eee;
}

.nav-tabs>li>a {
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
    color: #000;
}

/*.nav > li > a {
    position: relative;
    display: block;
    padding: 10px 10px;
    }*/

.tab-content {
    padding: 5px;
}

/*APARTADOS*/

/* INDEX */

.home .well {
    text-align: center;
    margin-top: 5px;
}

.home .well h4 {
    font-family: Actor;
    color: #666666;
}

.home .well p {
    color: #666666;
}

.home .prod {
    /*position: absolute;*/
    width: 100%;
}

/*INDEX*/

.bg-index {
    background: #003366;
    color: #fff;
}

.bg-index-1 {
    background: url(/dist/images/bg-index-1.jpg) center center;
    background-size: cover;
    color: #fff;
}

.bg-index-2 {
    background: url(/dist/images/bg-index-2.jpg) center center;
    background-size: cover;
    color: #fff;
}

.bg-index-1 a,
.bg-index-2 a {
    color: #333;
}

.bg-index-1 a:hover,
.bg-index-2 a:hover {
    color: #666;
}

.text-index {
    margin: 30px;
}

.text-index p {
    font-size: 1.5rem;
}

.bg-gray {
    background-color: #DADADA;
    p {
        margin-bottom: 0px !important;
        color: #999;
        font-size: 13px;
    }
}

@media (max-width: 1600px) {
    .text-index p {
        font-size: 1rem;
    }
}

/*PADDING TEXT*/

.padding-text {
    padding-top: 16.5%;
    padding-bottom: 16.5%;
}

/* SERVICIOS */

.servicios img {
    width: 100%;
    min-width: 940px;
    box-shadow: 0 0 5px #000;
    z-index: 1;
}

.titulo {
    margin-top: -20px;
    z-index: 100;
}

.titulo img {
    /*z-index: 80;*/
    position: absolute;
}

.titulo h1 {
    font-size: 70px;
    font-weight: 200;
    color: #666666;
}

.titulo h3 {
    font-size: 35px;
    font-weight: 100;
    color: #666666;
}

.serv p,
.serv li {
    color: #666666;
}

.serv .well h1 {
    font-size: 35px;
    font-weight: 200;
    color: #666666;
}

.serv .well p {
    color: #666666;
}

/* PRODUCTOS */

.productos .well {
    text-align: center;
    margin-top: -20px;
    color: #666666;
}

/* INSTALACIONES */

.inst-min img {
    margin-right: 6.8px;
    margin-left: 6.8px;
    border-radius: 3px;
}

/*THUMBNAIL*/

.thumbnail {
    border-radius: 0px;
    margin-bottom: 0px;
}

.thumbnail-no-border {
    border: none;
}

.caption {
    text-align: center;
}

/*IMG*/

.img-full-width {
    padding: 0px;
    margin: 0px;
}

.img-full-width img {
    width: 100%;
}

.img-space img {
    margin-bottom: 15px;
}

/*COLUMNAS*/

.col-gray {
    background-color: #CCC;
}

/*BLOCKQUOTE*/

blockquote {
    border-left: 3px dotted #09C;
}

blockquote strong {
    color: #09C;
}

/*JUMBOTRON*/

.jumbotron {
    font-weight: 300;
    margin-bottom: 0px;
}

.jumbotron p {
    font-weight: 400;
}

/*BTNS*/

.btn-default:hover,
.btn-default:focus,
.btn-default.focus,
.btn-default:active,
.btn-default.active,
.open>.dropdown-toggle.btn-default {
    color: #fff;
    background-color: #CCC;
    border-color: #adadad;
}

/*HVR EFFECTS*/

.hvr-glow {
    padding: 0px;
    margin: 0px;
}

/*IMAGES*/

.img-center {
    margin: auto;
}

.img-logo-footer {
    margin-top: -3.33%;
}

/*SOCIAL MEDIA*/

/* zocial */

[class*="zocial-"]:before {
    font-family: 'zocial', sans-serif;
}

.social a {
    font-size: 25px;
    text-decoration: none;
    text-align: center;
}

.social-header a {
    font-size: 25px;
    margin-right: -15px;
}

/*FOOTER*/

.footer {
    background-color: rgba(255, 255, 255, .35);
    border-top: 1px solid #CCC;
    padding: 25px;
    color: #666;
}

.footer a {
    text-decoration: none;
    color: #666666;
}

.footer a:hover {
    text-shadow: 0 0 10px #fff;
    color: #000;
}

.footer h2 {
    font-weight: 700;
}

.footer h3,
.footer h4 {
    font-weight: 300;
}

.fa-cx {
    width: 25px !important;
}