@import 'variables';

@import '~animate.css';

@import '~hover.css/scss/hover';

@import '~bootstrap/scss/bootstrap';

@import 'shadows';

@import 'carousel';

@import 'navbar';

@import 'theme';
