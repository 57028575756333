.language-selector {
    top: 20px;
    right: 10px;
    z-index: 999;
    @extend .position-absolute;
}

.navbar-brand {
    img {
        z-index: 999;
        width: 240px;
        margin-top: auto;
    }
}

.nav-item {
    padding-top: 10px;
    padding-bottom: 10px;
}

.navbar-light .navbar-nav {
    .active>.nav-link {
        color: $activeColor !important;
    }
    .nav-link {
        &.active,
        &.show {
            color: $activeColor !important;
        }
    }
    .show>.nav-link {
        color: $activeColor !important;
    }
}

.hvr-overline-from-center-active {
    &:before {
        left: 0;
        right: 0;
    }
}